<template>
  <div>
    <div class="journey_selection-container">
      <button 
        v-for="(section, index) in journey[firm]" 
        @click="jumpToStep(index)"
        class="journey_selection-btn"
        :class="{ 'journey_selection-btn-actif': index === journeyIdx}">
        {{ $t(section.name) }}
      </button>
    </div>
    <div class="progress mt-2">
      <div
        class="progress-bar progress-bar-success"
        role="progressbar"
        :style="calcStepSize"
      >
        {{ $t('STEP_OUT_OF', { etape: journeyIdx+1, etape_total: journey[firm].length }) }}
      </div>
    </div>
    <button class="btn btn-primary btn-sm mt-5" @click="preFill()">
      <i class="fa fa-list-ol"></i>
      Data
    </button>
    <div class="mt-1 tab-content">
      <component :is="journey[firm][journeyIdx].component" @next="next"/>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';

import preFillData from "@/data/v2_defaultNewAccount.json"
import emptyPreFillData from "@/data/v2_emptyNewAccount.json"
import riskPrefFillData from "@/data/defaultRisk.json"

import kycRiskValidation from '@/mixins/kycRiskValidation';

import ClientInfo from "@/components/agent/nouveau_compte/client.vue"
import Apports from "@/components/agent/nouveau_compte/apports.vue"
import ESG from "@/components/agent/nouveau_compte/esg.vue"
import KYC from "@/components/agent/nouveau_compte/kyc.vue"
import KycGenerali from "@/components/agent/nouveau_compte/kycGenerali.vue"
import Constraints from "@/components/agent/nouveau_compte/contraintes.vue"
import Goals from "@/components/agent/nouveau_compte/objectifs.vue"
import Simulation from "@/components/agent/nouveau_compte/simulation.vue"
import Resume from "@/components/agent/nouveau_compte/resume.vue"
import Visualisation from "@/components/agent/nouveau_compte/visualisation.vue"

export default {
  mixins: [kycRiskValidation],
  components: { 
    ClientInfo, 
    Apports,
    ESG,
    KYC,
    KycGenerali,
    Constraints,
    Goals,
    Simulation,
    Resume,
    Visualisation
  },
  data() {
    return {
      firm: "",
      journey: {
        "LCL": [
          { component: ClientInfo, name: "INFOS" },
          { component: ESG, name: "ESG" },
          { component: KycGenerali, name: "KYC" },
          { component: Constraints, name: "CONSTRAINTS_AND_PREF" },
          { component: Goals, name: "GOALS" },
          { component: Simulation, name: "SIMULATION" },
          { component: Resume, name: "SUMMARY" },
          { component: Visualisation, name: "VISUALIZATION", }
        ],
        "La Française": [
          { component: ClientInfo, name: "INFOS" },
          { component: Apports, name: "YOUR_SAVINGS" },
          { component: ESG, name: "ESG" },
          { component: KYC, name: "KYC" },
          { component: Constraints, name: "CONSTRAINTS_AND_PREF" },
          { component: Goals, name: "GOALS" },
          { component: Simulation, name: "SIMULATION" },
          { component: Resume, name: "SUMMARY" },
          { component: Visualisation, name: "VISUALIZATION" }
        ]
      },
      journeyIdx: 0
    }
  },
  computed: {
    ...mapState({
      breadLink: (state) => state.breadLink,
      baseUrl: (state) => state.baseUrl,

    }),
    calcStepSize(){
      let stepSize = 100/this.journey[this.firm].length
      return `width: ${stepSize + stepSize*this.journeyIdx}%`
    }
  },
  methods: {
    ...mapActions([
      "setBreadLink",
      "setNewCompte",
      "setStoreInfoToOrigin",
      "setRisk",
      "setNewNoteVisu"
    ]),
    async checkLog() {
      let isLog = this.$getIsLogged();
      if (!isLog) {
        this.setLogged({ isLog: false, role: 0 });

        await this.$http.get(this.baseUrl + 'auth/logout');

        localStorage.removeItem('priradToken');
        localStorage.removeItem('priradRole');
        localStorage.removeItem('priradCompany');
        this.$router.push('/login');
      } else {
        let role = this.$getRole();
        if (role != 10) {
          this.setLogged({ isLog: false, role: 0 });

          await this.$http.get(this.baseUrl + 'auth/logout');

          localStorage.removeItem('priradToken');
          localStorage.removeItem('priradRole');
          localStorage.removeItem('priradCompany');
          this.$router.push('/login');
        }
      }
    },
    init(){
      this.firm = localStorage.getItem("priradCompany")
      this.setNewCompte(emptyPreFillData)
    },
    preFill(){
      this.setNewCompte(preFillData)
      this.setRisk(riskPrefFillData)
      this.setNewNoteVisu(2.5)
    },
    async simuPreSteps(){
      await this.createData()
      await this.callValidation()
      await this.note2Vol()
    },
    async checkBeforeNavigation(index){
      if ((this.journey[this.firm][index].name === "SIMULATION" || this.journey[this.firm][index].name === "VISUALIZATION") && this.firm === "La Française")
        await this.simuPreSteps()
      else if ((this.journey[this.firm][index].name === "SIMULATION" || this.journey[this.firm][index].name === "VISUALIZATION") && this.firm === "LCL")
        await this.note2Vol()
    },
    async jumpToStep(index){
      await this.checkBeforeNavigation(index)
      this.journeyIdx = index
    },
    async next(){
      await this.checkBeforeNavigation(this.journeyIdx + 1)
      this.journeyIdx++
    }
  },
  beforeMount() {
    this.setBreadLink(['Prime Radiant', 'COUSELORS', 'NEW_ACCOUNT']);
    this.init()
  },
  created() {
    this.checkLog();
  },
}
</script>

<style scoped>
  .journey_selection-container{
    display: flex;
    justify-content: center;
    gap: 5px;
  }
  .journey_selection-btn {
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: normal;
  }
  .journey_selection-btn-actif {
    background-color: #1f97d1;
    color: white;
  }
</style>