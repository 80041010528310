<template>
  <div class="popUpStyle" :class="{'popUpStyleNoQuestion': !question}">
    <i class="fa fa-times closePopUp" @click="$emit('closePopUp')"></i>
    <p class="text-center" :class="{'m-auto': !question}">{{message}}</p>
    <div class="popUpBlocBtnStyle" v-show="question">
      <button class="popUpBtnStyle" @click="returnAnswer(true)">{{$t('YES')}}</button>
      <button class="popUpBtnStyle" @click="returnAnswer(false)">{{$t('NO')}}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
    question: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    returnAnswer(yesNo) {
      this.$emit("answer", yesNo);
    },
  },
};
</script>

<style>
.popUpStyle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 0px 20px gray;
  width: 450px;
  height: 200px;
  position: fixed;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px;
  color: black;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.closePopUp {
  align-self: end;
  cursor: pointer;
}
.popUpStyle > p {
  word-break: break-word;
  margin: 0px;
  padding: 10px;
}
.popUpBlocBtnStyle {
  align-self: center;
}
.popUpBtnStyle {
  background-color: #fff;
  width: 80px;
  height: 50px;
  text-align: center;
  color: black;
  border: none;
  transition: all 0.2s ease-in-out;
}
.popUpBtnStyle:first-child {
  background-color: #F1F1F1;
  border-radius: 25px;
  margin-right: 5px;
}
.popUpBtnStyle:hover {
  transform: scale(1.05);
}
.popUpStyleNoQuestion {
  justify-content: start !important;
}
</style>